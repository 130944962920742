import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0];

export const dictionary = {
		"/blogs/[category]": [~3],
		"/blogs/[category]/[articleSlug]": [~4],
		"/exercises": [~5],
		"/exercises/[exercise]": [~6],
		"/programs": [~7],
		"/programs/[programSlug]": [~8],
		"/trainers": [~9],
		"/trainers/[trainerSlug]": [~10],
		"/[...contentPageSlug]": [~2]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';